<template>
  <div class="my-calculations">
    <h1 class="title mt-10 xl:mt-20">My calculations</h1>

    <div class="container-bg xl:mb-20 xl:p-16">
      <div class="overflow-x-auto max-w-7xl mx-auto rounded-md">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden">
            <table class="min-w-full text-center leading-none text-sm divide-y divide-grey-mediumLight whitespace-nowrap">
              <thead class="uppercase text-lg text-white bg-brand-dark">
                <tr>
                  <th class="py-4 px-5">feed mill</th>
                  <th class="py-4 px-5">Animal</th>
                  <th class="py-4 px-5">proact360 dose</th>
                  <th class="py-4 px-5">
                    date
                  </th>
                  <th class="py-4 px-5">
                    action
                  </th>
                </tr>
              </thead>

              <tbody class="font-medium divide-y divide-grey-mediumLight text-brand-semiMedium text-base">
                <template v-if="diets.length">
                  <tr
                    v-for="(diet, i) in diets"
                    :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    :key="diet.id"
                  >
                    <td class="py-4 px-5">
                      {{diet.name}}
                    </td>

                    <td class="py-4 px-5">
                      {{ animalType(diet.animal_id) && animalType(diet.animal_id).name }}
                    </td>

                    <td class="py-4 px-5">
                      {{diet.diet_phases[0] && diet.diet_phases[0].dose}}
                    </td>

                    <td class="py-4 px-5">
                      {{new Intl.DateTimeFormat().format(new Date(diet.created_at))}}
                    </td>

                    <td class="action py-4 px-5">
                      <div class="flex space-x-6 justify-center">
                        <button
                          class="text-brand-dark"
                          @click="onBeforeViewDiet(diet)"
                        >
                          <span class="icon" v-html="icons.eye.open"></span>
                        </button>

                        <button
                          class="text-red-dark"
                          @click="onBeforeDeleteDiet(diet)"
                        >
                          <span class="icon" v-html="icons.trash"></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-else>
                  <tr class="bg-white">
                    <td colspan="4" class="py-4 px-5">No data</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <modal
      :isOpen="isModalOpen"
      @close="onModalClose"
    >
      <template #title>
        <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
          <div class="attention">
            <span class="icon" v-html="icons.attention"></span>
          </div>

          <h3 class="sub-title text-2xl m-0 text-left">
            You are about to delete 1 feed mills
            <small>Are you sure you want to erase all the data that have been recorder in your account?</small>
          </h3>
        </div>
      </template>

      <template #button>
        <button class="remove" @click="onDelete">Delete Feed Mill</button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import ModalMixin from '@/mixins/ModalMixin';
import {mapActions, mapMutations, mapGetters} from 'vuex';

export default {
  name: 'MyCalculations',
  components: {
    Modal
  },

  mixins: [
    ModalMixin
  ],

  mounted() {
    this.getFeeds()
    this.$store.commit('setNavigation')
  },

  methods: {
    ...mapActions({
      fetchDiets: 'feed/fetchDiets',
      onDeleteDiet: 'feed/onDeleteDiet'
    }),

    ...mapMutations({
      setLoading: 'setLoading',
      setActiveDiet: 'feed/setActiveDiet',
      setResultMatrix: 'result/setResultMatrix',
    }),

    getFeeds() {
      this.fetchDiets().then(() => {
        this.setLoading(false)
      })
    },

    onBeforeViewDiet(diet) {
      this.setActiveDiet(diet)
      this.setResultMatrix({})
      this.$router.push({name: 'result'})
    },

    onBeforeDeleteDiet(diet) {
      this.setActiveDiet(diet)
      this.onModalOpen()
    },

    onDelete() {
      this.onDeleteDiet().then((name) => {
        this.onModalClose()
        this.getFeeds()

        this.$toast.open({
          type: 'error',
          message: `Diet <strong>${name}</strong> was deleted!`
        })
      })
    },
  },

  computed: {
    ...mapGetters({
      diets: 'feed/getDiets',
      animalType: 'animalType/animalTypeById'
    })
  }
};
</script>

<style scoped>

</style>
